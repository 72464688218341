import React, { Component } from "react"
import styled from "@emotion/styled"

import { theme } from "../particles/theme"
import Layout from "./layout.fr"
import SEO from "../seo"

const StyledMain = styled.div`
  margin-top: 10rem;

  @media (max-width: ${theme.breakpoints.tablet}) {
    margin-top: 5em;
  }

  table {
    width: 100%;
  }

  .buy {
  width: 100%;
  background: ${theme.colors.homeHardware.primary};
  display: inline-block;
  text-decoration: none;
  padding: 1rem 0;
  color: #fff;
  font-size: 1.8rem;
  text-align: center;
  cursor: pointer;
  box-sizing: content-box;
  border: 0;
  border-bottom: 3px solid ${theme.colors.homeHardware.hover};
  transition: 0.3s;
}

.reset {
  font-size 1.5em;
  margin-block-start .83em;
  margin-block-end .83em;
  font-weight: bold;
  text-transform: uppercase;
  color: ${theme.colors.homeHardware.primary};
  transition: outline .2s ease;
  cursor: pointer;
  text-decoration: none;
}

.reset:hover {
  color: ${theme.colors.homeHardware.hover};
}

.buy:hover {
  background: ${theme.colors.homeHardware.hover};
}

p {
  margin-block-start: .5em;
  margin-block-end: .5em;
}

.color {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  height: 160px;
  border: 1px solid #666;
}

`

class SelectProduct extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }
  render() {
    let colors = this.props.pageContext.color;
    let idh = [];
    let idhmax = [];
    let quad_fr_url = [];
    let quad_max_fr_url = [];
    let quad_fr_description = [];
    let quad_max_fr_description = [];
    let unavailable = [];
    let delimiter = ', ';

    // make separate table rows for each idh/idhmax#
    if(colors.multivalIdh) {
      idh = colors.idh.split(delimiter);
      quad_fr_url = colors.quad_fr_url.split(delimiter);
      quad_fr_description = colors.quad_fr_description.split(delimiter);
    }
    else {
      idh.push(colors.idh);
      quad_fr_url.push(colors.quad_fr_url);
      quad_fr_description.push(colors.quad_fr_description);
    }

    if(colors.multivalIdhMax) {
      idhmax = colors.idhmax.split(delimiter);
      quad_max_fr_url = colors.quad_max_fr_url.split(delimiter);
      quad_max_fr_description = colors.quad_max_fr_description.split(delimiter);
      unavailable = colors.unavailable.split(delimiter);
    }
    else {
      idhmax.push(colors.idhmax)
      quad_max_fr_url.push(colors.quad_max_fr_url)
      quad_max_fr_description.push(colors.quad_max_fr_description)
      unavailable.push(colors.unavailable)
    }

    let colorOptions = [];
    let tempColor = {};
    for (let i = 0; i < idh.length; i++) {
      tempColor = {};
      tempColor.idh = idh[i];
      tempColor.idhmax = '';
      tempColor.link = quad_fr_url[i];
      tempColor.description = quad_fr_description[i];
      colorOptions.push(tempColor)
    }
    for (let j = 0; j < idhmax.length; j++) {
      tempColor = {};
      tempColor.idh = '';
      tempColor.idhmax = idhmax[j];
      tempColor.link = quad_max_fr_url[j];
      tempColor.description = quad_max_fr_description[j];
      tempColor.unavailable = unavailable[j];
      colorOptions.push(tempColor)
    }
    return (
      <Layout>
        <SEO title="Agencement des Couleurs" />
        <StyledMain>
          <a className="reset" href="/fr/">Réinitialiser la recherche</a>
          <h2>Affichage actuel:</h2>
          <h3>Code Couleur LePage&reg; QUAD MAX / QUAD {this.props.pageContext.color.osi}</h3>
          <p>Code couleur LePage&reg;: {this.props.pageContext.color.osi}</p>
          <p>QUAD MAX IDH#: {this.props.pageContext.color.idhmax}</p>
          <p>QUAD IDH#: {this.props.pageContext.color.idh}</p>
          <section className= "color" style={{background: `#${this.props.pageContext.color.colors.adjusted_rgb}`}}></section>
          <table>
            <tbody>
              <tr key='header'>
                <td style={{width: "10%"}}>
                  <p><strong>IDH/MAX#</strong></p>
                </td>
                <td style={{width: "45%"}}>
                  <p><strong>La description</strong></p>
                </td>
                <td style={{width: "20%"}}>
                </td>
              </tr>
              {colorOptions.map((color) => {
                let key = color.idh + ' ' +  color.idhmax;
                return <tr key={key}>
                         <td><p>{color.idh}{color.idhmax}</p></td>
                         <td><p>{color.description}</p></td>
                         <td><p>{color.unavailable ? 'Le produit QuadMax pour cette couleur est temporairement indisponible.' : color.link ? <a className="buy" href={color.link}>Acheter maintenant</a> : 'Contactez votre Home Hardware local pour les commandes spéciales.'}</p></td>
                       </tr>
              })}
            </tbody>
          </table>
        </StyledMain>
      </Layout>
    )
  }
}

export default SelectProduct
